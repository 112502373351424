import {useEffect, useState} from "react";
import {
    XAxis,
    ResponsiveContainer,
    YAxis,
    PieChart,
    Pie,
    Tooltip,
    Cell, Legend, Bar, BarChart
} from 'recharts';
import './Dashboard.scss';
import {Stat, Story} from "../../structs/DataTypes";
import {Link, useNavigate} from "react-router-dom";
import API from "../../API";


function Dashboard(this: any) {

    const [stats, setStats] = useState({} as Stat);
    const [barChartData, setBarChartData] = useState<{[key: string]: any}[]>([]);
    const [stories, setStories] = useState([] as Story[]);
    const navigate = useNavigate();


    useEffect(() => {
        if (!sessionStorage.getItem("session_key")) {
            navigate("/");
            return;
        }
        API.getStories().then(stories => setStories(stories));
        API.getStats().then(statsResponse => {
            setStats(statsResponse);
            setBarChartData(statsResponse.submissionsPerTopic)
        });
    }, [navigate]);
    const typePieChartData = [
        {
            'mediaType':"Audio",
            "value": stats.totalAudioSubmissions,
            "color": '#5ecae6'
        },
        {
            'mediaType' :"Video",
            'value': stats.totalVideoSubmissions,
            'color': '#60bbd1'
        },
        {
            'mediaType' :"Text",
            "value": stats.totalTextSubmissions,
            'color': '#509fb3'
        },
        {
            'mediaType' :"Photo",
            "value": stats.totalPhotoSubmissions,
            'color': '#427b8a'
        }
    ]

    const cardPieChartData = [
        {
            'cardType':"Go",
            "value": stats.totalGoStories,
            "color": '#5ecae6'
        },
        {
            'cardType' :"Create",
            'value': stats.totalCreateStories,
            'color': '#60bbd1'
        },
        {
            'cardType' :"Talk",
            "value": stats.totalTalkStories,
            'color': '#509fb3'
        },
    ]

    const truncate = (input: string) => input.length > 90 ? `${input.substring(0, 90)}...` : input
    return (
        <div className='dashboard'>
            <div className="column-content w-col w-col-10">
                <div className="page-header">
                    <div className='greeting-message'>
                        <div className='pink-bar'/>
                        <h1>Hello Wonderful Person!</h1>
                        <div className='pink-bar'/>
                    </div>

                </div>
                <div className='section-header'>
                    <h2>Recent User Activity</h2>
                </div>
                <div className="flexbox">
                    <div className="div-block-5">
                        <h4 className="heading-6">Total Stories</h4>
                        <h1 className="heading">{ stats && stats.totalStories} Stories</h1>
                    </div>
                    <div className="div-block-5">
                        <h4 className="heading-6">Total Prompts</h4>
                        <h1 className="heading">{stats && stats.totalPrompts} prompts</h1>
                    </div>
                    <div className="div-block-5">
                        <h4 className="heading-6">Most Popular Topic</h4>
                        <h1 className="heading">{stats.popularTopic?.popularTopicName} ({stats.popularTopic?.popularTopicSubmissions}) </h1>
                    </div>
                </div>
                <div className='section-header'>
                    <h2>Overview of Activity</h2>
                </div>
                <div className="grid graphGrid">
                    <div className="div-block-8 siteVisitOverview">
                        <h3>Site Visit Overview</h3>
                        <div className={'titleAndDateSelectorBox'}>
                            <div className={'titleBox'}/>
                            <div className={'overviewDateDropdown'}/>
                        </div>
                        <div className={'timeSelectorBox'}/>
                        <div className={'lineGraph'}>
                            <ResponsiveContainer width='92%' height='85%'>
                                <iframe width="600" height="450" title={"Google Analytics Data"}
                                        src="https://datastudio.google.com/embed/reporting/54a7aa1a-196d-4364-b6d1-7d2d3cfcc52a/page/waftC"
                                        frameBorder="0" allowFullScreen/>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    <div className="div-block-8 typeSubmissions">
                        <h3>Submissions by Type</h3>

                        <div className={'typeSubmissionsDateDropdown'}/>
                        <div className={'typeSubmissionsPieChart'}>
                            <ResponsiveContainer>
                               <PieChart width={600} height={500}>
                                   <Pie
                                       legendType={'square'}
                                       data={typePieChartData}
                                       dataKey={'value'}
                                       nameKey='mediaType'
                                       isAnimationActive={true}
                                       >
                                       {typePieChartData.map((entry) => <Cell key={`cell-${entry.value}`} fill={entry.color}/>)}
                                   </Pie>
                                   <Tooltip/>
                                   <Legend/>
                               </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    <div className="div-block-8 topicSubmissions">
                        <h3>Submissions By Topic</h3>
                        <ResponsiveContainer width={'100%'} height={'90%'}>
                        <BarChart width={550} height={450} data={barChartData} >
                            <XAxis dataKey={'Topic'}/>
                            <YAxis
                                dataKey={'Submissions'}
                            />
                            <Tooltip/>
                            <Legend/>
                            <Bar
                                dataKey={'Submissions'}
                                fill={'#5ecae6'}
                            />
                        </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div id="w-node-_6f653a9e-c845-d09c-4bd8-546971c00a1d-fa9391a3" className="div-block-8 cardUsage" >
                        <h3>Card Usage</h3>
                        <div className={'cardUsageDateDropdown'}/>
                        <div className={'cardUsagePieChart'}>
                            <ResponsiveContainer>
                                <PieChart width={550} height={500}>
                                    <Pie
                                        legendType={'square'}
                                        data={cardPieChartData}
                                        dataKey={'value'}
                                        nameKey='cardType'
                                        isAnimationActive={true}
                                    >
                                        {cardPieChartData.map((entry) => <Cell key={`cell-${entry.value}`} fill={entry.color}/>)}
                                    </Pie>
                                    <Tooltip/>
                                    <Legend/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>

                    </div>
                    <div className="div-block-8 recentStories" >
                        <h3>Recent stories</h3>
                        <div className={'recentStoriesBox'}>
                            {stories && stories.slice(0,3).map( (story,index) => {
                                return(
                                    <Link key={index} style={{textDecoration:"none"}} to={`/stories/${story.id}`}>
                                    <div className={'storyBox'} >
                                        <div>
                                            <h4>Message:</h4>
                                            <div
                                                className={"innerMessageDiv"}
                                                dangerouslySetInnerHTML={
                                                    {__html: story.message ?
                                                            truncate(story.message) : "<p>No Message</p>"}
                                                }/>
                                        </div>
                                        <h4>Type: {story.media_type}</h4>
                                        <h4>Card: {story.card} </h4>
                                    </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default Dashboard;