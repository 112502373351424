import {Prompt} from "../../structs/DataTypes";
import TextareaAutosize from "react-autosize-textarea";
import {useState} from "react";
import "./PromptReview.scss";
import "../StoryEdit/StoryEdit.scss";
import Check from "../../assets/check-circle_outline-white.svg";
import DeleteIcon from "../../assets/x-circle_outline-white.svg";

type PromptReviewProps = {
    prompt: Prompt;
    onClickUpdate: (updatePayload: PromptUpdatePayload) => void;
    onClickDelete: () => void;
}

type PromptUpdatePayload = {
    [key: string]: any;
    text?: string;
}

const PromptReview = ({prompt, onClickUpdate, onClickDelete}: PromptReviewProps) => {
    const [promptUpdatePayload, setPromptUpdatePayload] = useState({} as PromptUpdatePayload);

    return (
        <div className="prompt-review">
            <div className="edit-area">
                <div className="header-cell initial-header-cell">
                    <h4>Topic</h4>
                </div>
                <div className="content-cell initial-content-cell">
                    <p>{prompt.topic_name}</p>
                </div>
                <div className="header-cell">
                    <h4>Activity</h4>
                </div>
                <div className="content-cell">
                    <p>{prompt.card.charAt(0).toUpperCase() + prompt.card.slice(1)}</p>
                </div>
                <div className="header-cell">
                    <h4>Media Type</h4>
                </div>
                <div className="content-cell">
                    <p>{prompt.media_type.charAt(0).toUpperCase() + prompt.media_type.slice(1)}</p>
                </div>
                <div className="header-cell">
                    <h4>Text</h4>
                </div>
                <div className="content-cell">
                    <TextareaAutosize defaultValue={prompt.text} onChange={e => {
                        const tempUpdatePayload = {...promptUpdatePayload}
                        tempUpdatePayload.text = e.currentTarget.value;
                        setPromptUpdatePayload(tempUpdatePayload);
                    }} />
                </div>
                {prompt.media &&
                <div className="header-cell">
                    <h4>Media</h4>
                </div>}
                {prompt.media &&
                <div className="content-cell">
                    {prompt.media_type === "photo" &&  <img src={prompt.media} alt={""} />}
                    {prompt.media_type === "audio" &&  <audio controls={true} src={prompt.media}/>}
                    {prompt.media_type === "video" && <video controls={true} src={prompt.media}/>}
                </div>}
                {prompt.thumbnail &&
                <div className="header-cell">
                    <h4>Thumbnail</h4>
                </div>}
                {prompt.thumbnail &&
                <div className="content-cell">
                    <img src={prompt.thumbnail} alt={""} />
                </div>}
            </div>
            <div className={"button-area"}>
                <a href="#" onClick={() => window.location.reload()}>Go Back</a>
                <button type="submit" className="approve-btn" onClick={() => onClickUpdate(promptUpdatePayload)}>
                    Update
                    <img src={Check} alt="" className="check-icon"/>
                </button>
                <button className="delete-btn" onClick={onClickDelete}>
                    Delete
                    <img src={DeleteIcon} alt="" className="delete-icon"/>
                </button>
                <p>
                    Deleting a prompt will also delete all stories created for that prompt permanently. Deleting a
                    prompt cannot be undone.
                </p>
            </div>
        </div>
    );
};

export default PromptReview;