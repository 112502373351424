import {useEffect, useState} from "react";
import "./Stories.scss";
import "./Stories.scss"
import TimeCounting from "time-counting";
import {Link, useNavigate} from "react-router-dom";
import {Story, Topic} from "../../structs/DataTypes";
import GreenCheck from "../../assets/check-circle_outline-green.svg";
import AudioIcon from "../../assets/volume-2_bold.svg";
import TextIcon from "../../assets/note_bold.svg";
import PhotoIcon from "../../assets/image-1_bold.svg";
import VideoIcon from "../../assets/film-1_bold.svg";
import API from "../../API";

type FilterProps = {
    [key: string]: any;
}

function Stories() {

    const [stories, setStories] = useState([] as Story[]);
    const [topics, setTopics] = useState([] as Topic[]);
    const [filterProps, setFilterProps] = useState({} as FilterProps);
    const [selectedFilterButton, setSelectedFilterButton] = useState("all");
    const [activityFilterActive, setActivityFilterActive] = useState(false);
    const [topicFilterActive, setTopicFilterActive] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!sessionStorage.getItem("session_key")) {
            navigate("/");
            return;
        }
        API.getStories().then((stories) => setStories(stories));
        API.getTopics().then((topics) => setTopics(topics));

        const params = new URLSearchParams(window.location.search);
        if(params.has('topic')){
            handleTopicFilterSelectionMade(params.get('topic'))
        }
    }, [navigate]);

    const getStoryTableRows = () => {
        return stories
            .filter(filterStory)
            .map((story) => {
                return (
                    <tr key={`story:${story.id}`} className={story.approved ? "" : "unapproved"}>
                        <td>
                            {TimeCounting(story.created_at, {objectTime: Date.now()})}
                        </td>
                        <td>
                            {story.topic_name}
                        </td>
                        <td>
                            {story.card}
                        </td>
                        <td>
                            <p className="prompt-text">
                                {story.prompt && story.prompt.text}
                            </p>
                        </td>
                        <td>
                            {selectMediaTypeImage(story["media_type"])}
                            <Link to={`${story.id}`} className="edit-link">Edit {`>`}</Link>
                        </td>
                        <td>
                            {story.approved ?
                                <img src={GreenCheck} alt="Green Check"/> :
                                <Link to={`${story.id}`} className="review-link">Review Now {`>`}</Link>}

                        </td>
                    </tr>
                );
            })
    };

    const filterStory = (story: Story): boolean => {
        let matchesFilterProps = true;
        Object.keys(filterProps).forEach((key) => {
            if (filterProps[key] != story[key]) {
                matchesFilterProps = false;
            }
        });
        return matchesFilterProps;
    };

    const selectMediaTypeImage = (mediaType: string) => {
        switch (mediaType) {
            case "audio":
                return (<img src={AudioIcon} alt={"Audio"}/>);
            case "photo":
                return (<img src={PhotoIcon} alt={""}/>);
            case "video":
                return (<img src={VideoIcon} alt={"Video"}/>);
            default:
                return (<img src={TextIcon} alt={"Text"}/>);
        }
    }

    const handleClickFilter = (newFilterProps: FilterProps) => {
        setFilterProps(newFilterProps);
    };

    const handleActivityFilterSelectionMade = (cardName: string) => {
        const newFilterProps = {...filterProps};
        if (cardName === "") {
            delete newFilterProps["card"];
            setFilterProps(newFilterProps);
            return
        }
        newFilterProps["card"] = cardName;
        setFilterProps(newFilterProps);
    };

    const handleTopicFilterSelectionMade = (topicName: string|null) => {
        if(topicName === null){
            return;
        }
        const newFilterProps = {...filterProps};
        if (topicName === "") {
            delete newFilterProps["topic_name"];
            setFilterProps(newFilterProps);
            return;
        }
        newFilterProps["topic_name"] = topicName;
        setFilterProps(newFilterProps);
    }

    return (
        <div className="column-content w-col w-col-10">
            <h2 className="sectionheader">Stories</h2>
            <div className="filter-container">
                <button className={selectedFilterButton === "all" ? "selected underlined" : ""}
                        onClick={() => {
                            handleClickFilter({});
                            setSelectedFilterButton("all");
                        }}>
                    View All
                </button>
                <button className={selectedFilterButton === "pending" ? "selected underlined" : ""}
                        onClick={() => {
                            handleClickFilter({approved: false});
                            setSelectedFilterButton("pending");
                        }}>
                    Pending
                </button>
                <button className={selectedFilterButton === "approved" ? "selected underlined" : ""}
                        onClick={() => {
                            handleClickFilter({approved: true});
                            setSelectedFilterButton("approved");
                        }}>
                    Approved
                </button>
                <select onChange={e => {
                    handleActivityFilterSelectionMade(e.target.value);
                    setActivityFilterActive(true);
                }}
                        defaultValue=""
                        className={activityFilterActive ? "selected" : ""}>
                    <option value="" disabled={true} hidden={true}>Activity</option>
                    <option value="">All Activities</option>
                    <option value="talk">Talk</option>
                    <option value="create">Create</option>
                    <option value="go">Go</option>
                </select>
                <select onChange={e => {
                    handleTopicFilterSelectionMade(e.target.value);
                    setTopicFilterActive(true);
                }}
                        defaultValue={""}
                        className={topicFilterActive ? "selected" : ""}>
                    <option value="" disabled={true} hidden={true}>Topic</option>
                    <option value="">All Topics</option>
                    {topics.length > 0 && topics.map((topic) => {
                        return (
                            <option key={topic.id}
                                    value={`${topic.name}`}>{topic.name}</option>
                        );
                    })}
                </select>
            </div>
            <table className={"stories-table"}>
                <thead>
                <tr>
                    <th>Submitted</th>
                    <th>Topic</th>
                    <th>Activity</th>
                    <th>Prompt</th>
                    <th>Type</th>
                    <th>Approved</th>
                </tr>
                </thead>
                <tbody>
                {stories.length > 0 && getStoryTableRows()}
                </tbody>
            </table>
        </div>
    );
}

export default Stories;