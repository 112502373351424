import React, {useRef, useState} from "react";
import "../PromptCreate/PromptCreate.scss";
import TextareaAutosize from "react-autosize-textarea";
import {API_URL} from "../../structs/Constants";

const PromptCreate = ({topicName}: { topicName: string; }) => {
    const [activity, setActivity] = useState("");
    const [mediaType, setMediaType] = useState("");
    const [text, setText] = useState("");
    const [mainMedia, setMainMedia] = useState<File | null>(null);
    const [thumbnail, setThumbnail] = useState<File | null>(null);

    const mainMediaInputRef = useRef<HTMLInputElement>(null!);
    const thumbnailInputRef = useRef<HTMLInputElement>(null!);
    const formRef = useRef<HTMLFormElement>(null!);

    const allowedFileTypes: {
        [key: string]: string;
        photo: string;
        audio: string;
        video: string;
    } = {
        photo: "image/png, image/jpeg, image/gif",
        audio: "audio/*",
        video: "video/*",
    };

    const onMediaTypeSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMediaType(e.target.value);
        if (mainMediaInputRef.current) {
            mainMediaInputRef.current.value = "";
        }
        if (thumbnailInputRef.current) {
            thumbnailInputRef.current.value = "";
        }
        setMainMedia(null);
        setThumbnail(null);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const requestData = new FormData();
        requestData.append("card", activity);
        requestData.append("media_type", mediaType);
        requestData.append("text", text);
        requestData.append("topic_name", topicName);
        if (mainMedia) {
            requestData.append("media", mainMedia)
        }
        if (thumbnail) requestData.append("thumbnail", thumbnail);
        fetch(`${API_URL}/prompts`, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("session_key")
            },
            body: requestData
        })
            .then(response => {
                if (response.ok) {
                    window.alert("Prompt successfully created!");
                    resetStates();
                    formRef.current.reset();
                    return;
                }
                throw new Error(response.statusText);
            })
            .catch(err => {
                window.alert("There was an issue while trying to submit a new prompt:\n" + err);
                return;
            });
    };

    const resetStates = () => {
        setMainMedia(null);
        setThumbnail(null);
        setText("");
        setMediaType("");
        setActivity("");
    };

    return (
        <div className="prompt-create">
            <form ref={formRef} onSubmit={handleSubmit}>
                <div className="select-container">
                    <label>
                        Activity *
                        <select defaultValue="" onChange={e => setActivity(e.target.value)} required={true}>
                            <option value="" disabled={true}/>
                            <option value="talk">Talk</option>
                            <option value="create">Create</option>
                        </select>
                    </label>
                    <label>
                        Media Type *
                        <select defaultValue="" onChange={onMediaTypeSelected} required={true}>
                            <option value="" disabled={true}/>
                            <option value="text">Text</option>
                            <option value="photo">Photo</option>
                            <option value="video">Video</option>
                            <option value="audio">Audio</option>
                        </select>
                    </label>
                </div>
                <h4>Prompt Text *</h4>
                <TextareaAutosize onChange={e => setText(e.currentTarget.value)} required={true}/>
                {mediaType && mediaType !== "text" &&
                <>
                    <label className="file-input-label" htmlFor="main-media-input">
                        Main Media *
                    </label>
                    <input id="main-media-input" type="file" accept={allowedFileTypes[mediaType]} required={true}
                           ref={mainMediaInputRef}
                           onChange={e => setMainMedia(e.target.files ? e.target.files[0] : null)}/>
                    {mediaType === "video" && <p>Upload an .mp4, .mov, or an .hevc file</p>}
                    {mediaType === "photo" && <p>Upload a .jpg, .png, or a .gif file</p>}
                    {mediaType === "audio" && <p>Upload a .mp3, .aac, or an .wav file</p>}
                </>

                }
                {mediaType && mediaType !== "text" && mediaType !== "photo" &&
                <>
                    <label className="file-input-label" htmlFor="thumbnail-input">
                        Thumbnail *
                    </label>
                    <input id="thumbnail-input" type="file" accept={allowedFileTypes.photo} required={true}
                           ref={thumbnailInputRef}
                           onChange={e => setThumbnail(e.target.files ? e.target.files[0] : null)}/>

                </>
                }
                <button type="submit" className="submit">Add Prompt</button>
            </form>
        </div>
    );
};

export default PromptCreate;