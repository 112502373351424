import {useEffect, useState} from "react";
import './StoryReview.scss';
import {useParams, NavLink, useNavigate} from "react-router-dom";
import StoryEdit from "../../components/StoryEdit/StoryEdit";
import {Story} from "../../structs/DataTypes";
import Check from "../../assets/check-circle_outline-white.svg";
import DeleteIcon from "../../assets/x-circle_outline-white.svg";
import {API_URL, DOMPURIFY_ALLOWED_TAGS} from "../../structs/Constants";
import DOMPurify from "dompurify";
import API from "../../API";

type StoryUpdatePayload = {
    [key: string]: any;
    name?: string;
    message: string;
    approved?: boolean;
    featured?: boolean;
    media_type?: string;
    remove_media?: boolean;
}

function StoryReview() {

    const [story, setStory] = useState({} as Story);
    const [storyUpdatePayload, setStoryUpdatePayload] = useState({} as StoryUpdatePayload);
    const nav = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        if (!sessionStorage.getItem("session_key")) {
            nav("/");
            return;
        }
        API.getStory(id!).then((story) => setStory(story));
    }, [id]);

    const saveStory = () => {
        const purifiedPayload = {...storyUpdatePayload}
        purifiedPayload.message = DOMPurify.sanitize(purifiedPayload.message, {ALLOWED_TAGS: DOMPURIFY_ALLOWED_TAGS});
        fetch(`${API_URL}/stories/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + sessionStorage.getItem("session_key"),
            },
            body: JSON.stringify(purifiedPayload),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(response.statusText);
            })
            .then(editedStory => {
                setStory({...editedStory} as Story);
                setStoryUpdatePayload({} as StoryUpdatePayload);
                window.alert("The story has been successfully updated.");
                nav("/stories");
            }).catch(error => {
            window.alert("Something went wrong while trying to update the story, please try again.");
            console.log(error);
        });
    }

    const updateStoryUpdatePayloadProp = (key: string, value: any) => {
        if (key === "remove_media" && value === true) {
            const tempStory = {...story};
            tempStory.media = "";
            setStory(tempStory)
        }
        if (key === "media_type") {
            const tempStory = {...story};
            tempStory.media_type = value;
            setStory(tempStory);
        }
        const tempUpdatePayload = {...storyUpdatePayload} as StoryUpdatePayload;
        tempUpdatePayload[key] = value;
        setStoryUpdatePayload(tempUpdatePayload);
    };

    const handleClickDeleteStory = () => {
        if (window.confirm("Do you wish to delete this story? If you confirm the delete cannot be undone.")) {
            fetch(`${API_URL}/stories/${id}`, {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("session_key"),
                }
            })
                .then(response => {
                    if (response.ok) {
                        nav("/stories");
                        return;
                    }
                    throw new Error(response.statusText);
                })
                .catch(error => {
                    window.alert("Something went wrong while trying to delete the story, please try again.");
                    console.log(error);
                });
        }
    }

    return (
        <div className="column-content w-col w-col-10">
            <h2 className="sectionheader">Review and Edit Story</h2>
            <div className="w-form">
                <form id="email-form" name="email-form" data-name="Email Form" onSubmit={(e) => {
                    e.preventDefault();
                    saveStory();
                }}>
                    <div className="w-row">
                        <div className="w-col w-col-8">
                            {story.id > 0 && <StoryEdit story={story} onChangeProp={updateStoryUpdatePayloadProp}/>}
                        </div>
                        <div className="column-5 w-col w-col-4">
                            <h6 className="heading-12">Do you wish to update this submission?</h6>
                            <button type="submit" className="approve-btn">
                                Update
                                <img src={Check} alt="" className="check-icon"/>
                            </button>
                            <button className="delete-btn" onClick={e => {
                                e.preventDefault();
                                handleClickDeleteStory();
                            }}>
                                Delete
                                <img src={DeleteIcon} alt="" className="delete-icon"/>
                            </button>
                            <NavLink to={"/stories"} className="link-5">Review Later</NavLink>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default StoryReview;