import STBAlogo from "../../assets/STBA-Logo.svg";
import './ForgotPassword.scss'
import React, {useState} from "react";
import {API_URL} from "../../structs/Constants";
import {useNavigate} from "react-router-dom";

function ForgotPassword() {
    const [email, setEmail] = useState("");

    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetch(`${API_URL}/forgot-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
            }),
        })
            .then(response => {
                if (response.ok) {
                    window.alert("Password reset email has been sent. Redirecting you to login.")
                    navigate("/");
                    return;
                }
                throw new Error("Could not send email, please try again or try a different email.")
            })
            .catch(err => window.alert(err))
    };

    return (
        <div className='forgot-password'>
            <div className='forgot-password-heading'>
                <img src={STBAlogo} alt={"Small Town Big Art"}/>
                <h3>Administration</h3>
            </div>
            <div className='enter-email-box'>
                <h3>Forgot Password?</h3>
                <form className="enter-email-input" onSubmit={handleSubmit}>
                    <div className='enter-email-div'>
                        <label>Enter Email</label>
                        <input type="email" id='email-id' className="contains-input" required={true}
                               onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <button type='submit' className="reset-button">
                        Email Me Password Reset
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword;