import React, {useRef, useState} from "react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../structs/Constants";
import "./Users.scss"

type User = {
    email: string;
    created_at: string;
}

const Users = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([] as User[]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const formRef = useRef<HTMLFormElement>(null!);

    useEffect(() => {
        if (!sessionStorage.getItem("session_key")) {
            console.log(sessionStorage.getItem("session_key"));
            navigate("/");
            return;
        }
        fetch(`${API_URL}/users`, {headers: {"Authorization": "Bearer " + sessionStorage.getItem("session_key"),}})
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(response.statusText);
            })
            .then(response => setUsers(response))
            .catch(err => console.log(err));
    }, [navigate]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (password.length < 8) {
            window.alert("Password too short.");
            return;
        }
        fetch(`${API_URL}/users`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + sessionStorage.getItem("session_key"),
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(response.statusText);
            })
            .then(response => {
                formRef.current.reset();
                const tempUsers = [...users];
                tempUsers.push(response as User);
                setUsers(tempUsers);
            })
            .catch(err => window.alert("Could not create user:\n" + err));
    };

    return (
        <div className="users column-content w-row">
            <table>
                <colgroup>
                    <col className="email-column" />
                    <col className="date-column" />
                </colgroup>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Date Created</th>
                </tr>
                </thead>
                <tbody>
                {users && users.map(user => {
                    const date = new Date(user.created_at)
                    return (
                        <tr key={user.email}>
                            <td><p>{user.email}</p></td>
                            <td>{date.toLocaleDateString()}</td>
                        </tr>);
                })}
                </tbody>
            </table>
            <form onSubmit={handleSubmit} ref={formRef}>
                <h3>Create New User</h3>
                <div className="user-edit">
                    <div className="header-cell initial-header-cell">
                        <h4>Email</h4>
                    </div>
                    <div className="content-cell initial-content-cell">
                        <input type="text" required={true} onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <div className="header-cell">
                        <h4>Password</h4>
                    </div>
                    <div className="content-cell">
                        <input type="text" required={true} onChange={e => setPassword(e.target.value)}/>
                        <p>
                            Password must be a minimum of 8 characters long.
                        </p>
                    </div>
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default Users;