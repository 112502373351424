import React, {useState} from "react";
import "./ForgotPassword.scss";
import "./PasswordReset.scss";
import STBAlogo from "../../assets/STBA-Logo.svg";
import {API_URL} from "../../structs/Constants";
import {Link, useParams} from "react-router-dom";

const PasswordReset = () => {
    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [passwordBeenReset, setPasswordBeenReset] = useState(false);

    const {id, apiToken} = useParams();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validatePassword()) {
            fetch(`${API_URL}/users/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + apiToken,
                },
                body: JSON.stringify({
                    password: password,
                }),
            })
                .then(response => {
                    if (response.ok) {
                        setPasswordBeenReset(true);
                        return;
                    }
                    throw new Error(response.statusText);
                })
                .catch(err => window.alert("Could not reset password:\n" + err));
        }
    };

    const validatePassword = () => {
        if (!confirmPass) {
            setPasswordMismatch(false);
            return false
        } else if (password !== confirmPass) {
            setPasswordMismatch(true);
            return false;
        }
        setPasswordMismatch(false);
        return true;
    }

    return (
        <div className="forgot-password">
            <header className="forgot-password-heading">
                <img src={STBAlogo} alt={"Small Town Big Art"}/>
                <h3>Administration</h3>
            </header>
            {!passwordBeenReset &&
            <div className="form-container">
                <h3>Reset Password</h3>
                <form onSubmit={handleSubmit}>
                    <label>
                        New Password:
                        <input type="password" onChange={e => setPassword(e.target.value)} required={true}/>
                    </label>
                    <label>
                        Confirm Password:
                        <input type="password" onChange={e => setConfirmPass(e.target.value)} onBlur={validatePassword}
                               className={passwordMismatch ? "mismatch" : ""} required={true}/>
                    </label>
                    {confirmPass && passwordMismatch && <span>Password and Confirm Password do not match.</span>}
                    <button type="submit">Reset Password</button>
                </form>
            </div>}
            {passwordBeenReset &&
            <div className="form-container">
                <h2>Your password has been reset</h2>
                <p>Go back to <Link to={"/"}>Login</Link></p>
            </div>}
        </div>
    );
};

export default PasswordReset;