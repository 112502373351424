import React, {useState} from "react";
import ReactQuill from "react-quill";
import {Story} from "../../structs/DataTypes";
import "react-quill/dist/quill.snow.css";
import "./StoryEdit.scss";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import BlackX from "../../assets/x-circle_outline-black.svg";
import {DOMPURIFY_ALLOWED_TAGS} from "../../structs/Constants";

type StoryEditProps = {
    story: Story;
    onChangeProp: (key: string, value: any) => void;
}

const StoryEdit = ({story, onChangeProp}: StoryEditProps) => {
    const [editorText, setEditorText] = useState(story["message"] ?
        DOMPurify.sanitize(story["message"]!, {ALLOWED_TAGS: DOMPURIFY_ALLOWED_TAGS}) : "");
    const [name, setName] = useState(story.name ?? "Anonymous");

    const formatDataTag = (key: string) => {
        return (story[key].charAt(0).toUpperCase() + story[key].slice(1));
    };

    const handleClickDeleteMedia= (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to remove the media attached to this story?")) {
            onChangeProp("remove_media", true);
        }
    }

    return (
        <div className="story-edit">
            <div className={"header-cell initial-header-cell"}>
                <h4>Submitter Name</h4>
            </div>
            <div className={"content-cell initial-content-cell"}>
                <input type="text" value={name} onChange={(e) => {
                    setName(e.target.value);
                    onChangeProp("name", e.target.value);
                }}/>
            </div>
            <div className={"header-cell"}>
                <h4>Activity</h4>
            </div>
            <div className={"content-cell"}>
                <p className="data-tag">{formatDataTag("card")}</p>
            </div>
            <div className={"header-cell"}>
                <h4>Topic</h4>
            </div>
            <div className={"content-cell"}>
                <p className="data-tag">{formatDataTag("topic_name")}</p>
            </div>
            {story.prompt && <div className={"header-cell"}>
                <h4>Prompt</h4>
            </div>}
            {story.prompt && <div className={"content-cell"}>
                <p className={"data-tag"}>{story.prompt.text}</p>
            </div>}
            <div className={"header-cell"}>
                <h4>Submission Type</h4>
            </div>
            <div className={"content-cell"}>
                <select defaultValue={story["media_type"]} onChange={(e) => onChangeProp("media_type", e.target.value)}>
                    <option value="photo">Photo</option>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                    <option value="text">Text</option>
                </select>
            </div>
            <div className={"header-cell"}>
                <h4>Approved</h4>
            </div>
            <div className={"content-cell"}>
                <select defaultValue={story["approved"] ? "true" : "false"} onChange={(e) => {
                    if (e.target.value === "true") {
                        onChangeProp("approved", true);
                    } else {
                        onChangeProp("approved", false);
                    }
                }} >
                    <option value={"true"}>Yes</option>
                    <option value={"false"}>No</option>
                </select>
            </div>
            <div className={"header-cell"}>
                <h4>Featured</h4>
            </div>
            <div className={"content-cell"}>
                <select defaultValue={story["featured"] ? "true" : "false"} onChange={(e) => {
                    if (e.target.value === "true") {
                        onChangeProp("featured", true);
                    } else {
                        onChangeProp("featured", false);
                    }
                }}>
                    <option value={"true"}>Yes</option>
                    <option value={"false"}>No</option>
                </select>
            </div>
            <div className={"header-cell"}>
                <h4>Attached Text</h4>
            </div>
            <div className="content-cell quill-container">
                <ReactQuill theme="snow" defaultValue={editorText} onChange={(text) => {
                    setEditorText(text);
                    onChangeProp("message", text);
                }}
                            modules={{
                                toolbar: [
                                    [{header: [1, 2, 3, false]}],
                                    ["bold", "italic", "underline", "strike"],
                                    ["blockquote"],
                                ],
                            }}
                />
            </div>
            {story.media_type !== "text" &&
            <div className={"header-cell"}>
                <h4>Attached Media</h4>
            </div>}
            {story.media_type !== "text" && story.media &&
            <div className={"content-cell media-container"}>
                {story.media_type === "audio" && <audio controls={true} src={story.media}/>}
                {story.media_type === "video" && <video controls={true} src={story.media}/>}
                {story.media_type === "photo" &&
                <img src={story.media} alt={"unavailable"} className={"attached-img"}/>}
                <button className={"delete-media-btn"} onClick={handleClickDeleteMedia}>
                    <img src={BlackX} alt={"Delete Media"}/>
                </button>
            </div>}
            {story.media_type !== "text" && !story.media &&
            <div className={"content-cell"}>
                <p className={"data-tag"}>No media attached</p>
            </div>
            }
        </div>
    );
};

export default StoryEdit;