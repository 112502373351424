import {Topic, Stat, Prompt, Story} from "./structs/DataTypes";
import {API_URL} from "./structs/Constants";

const getStories = async (): Promise<Story[]> => {
    return await fetch(`${API_URL}/stories`, {
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("session_key"),
        }
    })
        .then((response) => {
            if (response.ok) return response.json();
            return {
                errorCode: response.status,
                error: response.statusText,
            }
        })
        .then((response) => {
            return response.map((story: any) => {
                return {
                    ...story
                } as Story
            });
        });
};

const getPrompts = async (): Promise<Prompt[]> => {
    return await fetch(`${API_URL}/prompts`)
        .then((response) => {
            if (response.ok) return response.json();
        })
        .then((response) => {
            return response.map((prompt: any) => {
                return {
                    ...prompt
                } as Prompt;
            });
        });
};

const getTopics = async (): Promise<Topic[]> => {
    return await fetch(`${API_URL}/topics`)
        .then((response) => {
            if (response.ok) return response.json();
        })
        .then((response) => {
            return response.map((topic: any) => {
                return {
                    ...topic
                } as Topic;
            });
        });
}

const getStory = async (id: string): Promise<Story> => {
    return await fetch(`${API_URL}/stories/${id}`)
        .then((response) => {
            if (response.ok) return response.json();
        });
}

const getTopicsWithPrompts = async (): Promise<Topic[]> => {
    return await fetch(`${API_URL}/topics/prompts`)
        .then((response) => {
            if (response.ok) return response.json();
        });
}

const getTopicWithPrompts = async (id: string): Promise<Topic> => {
    return await fetch(`${API_URL}/topics/${id}/prompts`)
        .then((response) => {
            if (response.ok) return response.json();
        });
};

const getStats = async (): Promise<Stat> => {
    return await fetch(`${API_URL}/stats`, {
        headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("session_key"),
        }
    })
        .then(response => {
            if (response.ok) return response.json();
        });
}

const API = {
    getStories,
    getStory,
    getPrompts,
    getTopics,
    getStats,
    getTopicsWithPrompts,
    getTopicWithPrompts
}
export default API;