import React, {useState, useRef} from "react";
import {API_URL} from "../../structs/Constants";
// @ts-ignore
import {Pannellum} from "pannellum-react"
import "./ThreeSixtyEdit.scss"
import TextareaAutosize from "react-autosize-textarea";
import BlackX from "../../assets/x-circle_outline-black.svg";
import {Prompt} from "../../structs/DataTypes";


const ThreeSixtyEdit = ({prompt}: { prompt: Prompt }) => {
    const [text, setText] = useState("");
    const [newThumbnail, setNewThumbnail] = useState<File | null>(null);
    const [changingThumbnail, setChangingThumbnail] = useState(false);

    const thumbnailInputRef = useRef<HTMLInputElement>(null!);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!text && !newThumbnail) {
            window.alert("No changes were made.");
            return;
        }
        const updatePayload = new FormData();
        updatePayload.append("text", text);
        if (newThumbnail) updatePayload.append("thumbnail", newThumbnail);
        fetch(`${API_URL}/prompts/${prompt.id}`, {
            method: "PUT",
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem("session_key") ?? "",
            },
            body: updatePayload,
        })
            .then(response => {
                if (response.ok) {
                    window.alert("Prompt successfully updated.")
                    return;
                }
                throw new Error(response.statusText);
            })
            .then(() => {
                setNewThumbnail(null);
                setChangingThumbnail(false);
                if (thumbnailInputRef.current) {
                    thumbnailInputRef.current.files = null;
                    thumbnailInputRef.current.value = "";
                }
            })
            .catch(err => {
                window.alert("There was an error while trying to update the topic:\n" + err)
            });
    };

    return (
        <div className="edit-360">
            {prompt.id && prompt.location &&
            <div className="location-container">
                <div className="location-text-container">
                    <h3>Location Name</h3>
                    <p>{prompt.location.name}</p>
                    <div className="coordinates-container">
                        <div>
                            <h3>Latitude</h3>
                            <p>{prompt.location.latitude}</p>
                        </div>
                        <div>
                            <h3>Longitude</h3>
                            <p>{prompt.location.longitude}</p>
                        </div>
                    </div>
                </div>
                <div className="location-image-container">
                    <img src={prompt.location.map} alt="map"/>
                    <Pannellum image={prompt.location.media}
                               autoLoad
                               width="100%"
                               height="100%"/>
                </div>
            </div>}
            {prompt.id && prompt.location &&
            <div className="prompt-container">
                <form onSubmit={handleSubmit}>
                    <h3>Prompt Text</h3>
                    <TextareaAutosize defaultValue={prompt.text} onChange={e => setText(e.currentTarget.value)}/>
                    <h3>Media</h3>
                    {prompt.media_type === "audio" && <audio controls={true} src={prompt.media ?? ""}/>}
                    {prompt.media_type === "video" && <video controls={true} src={prompt.media ?? ""}/>}
                    <h3>Thumbnail</h3>
                    {!changingThumbnail &&
                    <div>
                        <img src={prompt.thumbnail ?? ""} alt={prompt.thumbnail!.toString()}/>
                        <button className="change-btn" onClick={() => setChangingThumbnail(true)}>
                            <img src={BlackX} alt="Change thumbnail"/>
                        </button>
                    </div>}
                    {changingThumbnail &&
                    <div>
                        <input type="file" required={true} ref={thumbnailInputRef}
                               onChange={e => setNewThumbnail(e.target.files ? e.target.files[0] : null)}/>
                        <p>
                            Please select a new file to upload as the thumbnail, the old thumbnail will not be replaced
                            unless you press "Update Prompt".
                        </p>
                    </div>}
                    <button type="submit" className="update-button">Update Prompt</button>
                </form>
            </div>}
        </div>
    );
};

export default ThreeSixtyEdit;