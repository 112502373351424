import {NavLink} from "react-router-dom";
import './Sidebar.scss';
import STBALogo from "../../assets/STBA-Logo.svg";

function Sidebar() {
    return (
        <div className="column-sidebar navbar w-col w-col-2">
            <div className="nav-logo">
                <img src={STBALogo} loading="lazy" alt="STBA-Logo" className="logo"/>
                <h3>Admin</h3>
            </div>
            <ol className="list w-list-unstyled">
                <li>
                    <NavLink
                        to={"/dashboard"}
                        className={"nav-link main-menu-item"}
                    >
                        Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={"/stories"}
                        className={"nav-link main-menu-item"}
                    >
                        Stories
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={"/topics"}
                        className={"nav-link main-menu-item"}
                    >
                        Topics
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={"/users"}
                        className={"nav-link main-menu-item"}
                    >
                        Users
                    </NavLink>
                </li>
            </ol>
        </div>
    );
}

export default Sidebar;