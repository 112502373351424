import "./Login.scss"
import {Link, useNavigate} from "react-router-dom";
import STBAlogo from "../../assets/STBA-Logo.svg";
import loginIcon from "../../assets/door-open_bold.svg";
import React, {useState} from "react";
import {API_URL} from "../../structs/Constants";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return fetch(`${API_URL}/users/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
            .then(response => {
                if(response.ok) {
                    return response.json();
                }
                throw new Error("Invalid email or password");
            })
            .then(response => {
                sessionStorage.setItem("session_key", response.token);
                navigate("/dashboard");
            })
            .catch(err => {
                sessionStorage.clear();
                window.alert(err);
            })
    }

    return (
        <div className='login'>
            <div className='login-heading'>
                <img src={STBAlogo} alt={"Small Town Big Art"}/>
                <h3>Administration</h3>
            </div>
            <div className='login-box'>
                <h3>Login</h3>
                <form className="login-input" onSubmit={handleSubmit}>
                    <div className='email-div'>
                        <label>Email</label>
                        <input type="email" id='email-id' onChange={e => setEmail(e.target.value)}
                               className={email ? "contains-input" : ""}/>
                    </div>
                    <div className='password-div'>
                        <label>Password</label>
                        <input type="password" id='password-id' onChange={e => setPassword(e.target.value)}
                               className={password ? "contains-input" : ""}/>
                    </div>
                    <div className='bottom-links'>
                        <div className='forgot-password'>
                            <Link to={'/forgot'}>Forgot Password?</Link>
                        </div>
                        <button type='submit' className="login-button">
                            Login <img src={loginIcon} alt={""}/>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;