import React from 'react';
import './App.css';

import {Routes, Route, useLocation, Navigate} from "react-router-dom"

import Login from './pages/Login/Login';
import ForgotPassword from "./pages/Login/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Topics from "./pages/Topics/Topics";
import Stories from "./pages/Stories/Stories";
import Sidebar from "./components/Sidebar/Sidebar";
import StoryReview from "./pages/Stories/StoryReview";
import TopicReview from "./pages/Topics/TopicReview";
import PasswordReset from "./pages/Login/PasswordReset";
import Users from "./pages/Users/Users";

function App() {
    const location = useLocation();
    return (
        <div className="App">
            <div className={"w-row"}>
                {!["/", "/forgot"].includes(location.pathname) &&
                !location.pathname.match(/\/password-reset\//g) && <Sidebar/> /*Regex required for password reset page
                                                                                 since the path will contain an api token*/}
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path={"*"} element={<Navigate to={"/"}/>}/>
                    <Route path="/forgot" element={<ForgotPassword/>}/>
                    <Route path="/password-reset/:id/:apiToken" element={<PasswordReset/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="stories" element={<Stories/>}/>
                    <Route path="stories/:id" element={<StoryReview/>}/>
                    <Route path="users" element={<Users/>} />
                    <Route path="topics" element={<Topics/>}/>
                    <Route path="topics/:id" element={<TopicReview/>}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;
