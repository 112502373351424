import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import './Topic.scss';
import {useParams} from "react-router-dom";
import {Prompt, Topic} from "../../structs/DataTypes";
import TopicPrompts from "../../components/TopicPrompts/TopicPrompts";
import PromptCreate from "../../components/PromptCreate/PromptCreate";
import ThreeSixtyEdit from "../../components/360Edit/ThreeSixtyEdit";
import API from "../../API";

function TopicReview() {

    const [topic, setTopic] = useState<Topic | null>(null);
    const [section, setSection] = useState("prompts");

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!sessionStorage.getItem("session_key")) {
            navigate("/");
            return;
        }
        API.getTopicWithPrompts(id!).then(topic => setTopic(topic));
    }, [id]);

    const getGoPrompt = (prompts: Prompt[]) => {
        const filteredPrompts = prompts.filter(prompt => prompt.card === "go");
        return filteredPrompts[0];
    }

    return (
        <>
            {topic && <div className="edit-topic column-content">
                <div className="header-container">
                    <a href="#" onClick={() => navigate("/topics")}>{'<'} Go Back</a>
                    <h2>{topic.name}</h2>
                    <div className="spacing-box">{'<'} Go Back</div>
                </div>
                <div className="section-button-container">
                    <button className={section === "prompts" ? "selected" : ""} onClick={() => window.location.reload()}>
                        Topic Prompts
                    </button>
                    <button className={section === "create_prompt" ? "selected" : ""}
                            onClick={() => setSection("create_prompt")}>
                        Prompt Creation
                    </button>
                    <button className={section === "360" ? "selected" : ""} onClick={() => setSection("360")}>
                        360 Feature
                    </button>
                </div>
                {topic && section === "prompts" && <TopicPrompts topic={topic} />}
                {topic && section === "create_prompt" && <PromptCreate topicName={topic.name} />}
                {topic && topic.prompts && section === "360" && <ThreeSixtyEdit prompt={getGoPrompt(topic.prompts)}/>}
            </div>}
        </>
    );
}

export default TopicReview;