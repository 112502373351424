import {useState} from "react";
import {Prompt, Topic} from "../../structs/DataTypes";
import {API_URL} from "../../structs/Constants";
import "./TopicPrompts.scss"
import PromptReview from "../PromptReview/PromptReview";

interface TopicPromptsProps {
    topic: Topic;
}

type PromptUpdatePayload = {
    [key: string]: any;
    text?: string;
}

const TopicPrompts = ({topic}: TopicPromptsProps) => {
    const [promptBeingEdited, setPromptBeingEdited] = useState({} as Prompt);


    const handleClickUpdate = (updatePayload: PromptUpdatePayload) => {
        if (updatePayload.text === undefined) {
            window.alert("No updates can be made.");
            return;
        }
        fetch(`${API_URL}/prompts/${promptBeingEdited.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + sessionStorage.getItem("session_key")
            },
            body: JSON.stringify(updatePayload),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(response.statusText);
            })
            .then(response => {
                window.alert("Prompt Successfully updated.");
                setPromptBeingEdited({...response} as Prompt);
                return;
            })
            .catch(err => window.alert("There was an error while trying to update the prompt\n" + err));
    };

    const handleClickDelete = () => {
        if (window.confirm("Are you positive that you want to delete this prompt? Once a prompt has been deleted it cannot be undone.")) {
            fetch(`${API_URL}/prompts/${promptBeingEdited.id}`, {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("session_key"),
                }
            })
                .then(response => {
                    if (response.ok) {
                        window.alert("Prompt and all of its stories have been successfully deleted");
                        window.location.reload();
                        return;
                    }
                })
        }
    };

    return (
        <>
            {!promptBeingEdited.id &&
            <div className="topic-prompts">
                <table className="prompt-table">
                    <colgroup>
                        <col className="text-column"/>
                        <col className="activity-column"/>
                        <col className="media-column"/>
                        <col className="edit-column"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>Text</th>
                        <th>Activity</th>
                        <th>Media Type</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {topic.prompts && topic.prompts
                        .filter(prompt => prompt.card !== "go")
                        .map(prompt => {
                            return (
                                <tr key={prompt.id}>
                                    <td>{prompt.text}</td>
                                    <td>{prompt.card}</td>
                                    <td>{prompt.media_type}</td>
                                    <td className="link-cell">
                                        <a href="#" onClick={() => setPromptBeingEdited(prompt)}>Edit {">"}</a>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>}
            {promptBeingEdited.id &&
            <PromptReview prompt={promptBeingEdited} onClickUpdate={handleClickUpdate}
                          onClickDelete={handleClickDelete}/>
            }
        </>
    );
};

export default TopicPrompts;