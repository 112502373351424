import {useEffect, useState} from "react";
import './Topics.scss';
import {Link, useNavigate} from "react-router-dom";
import {Prompt, Topic} from "../../structs/DataTypes";
import photoPrompt from '../../assets/image-1_bold.svg';
import audioPrompt from '../../assets/volume-2_bold.svg';
import videoPrompt from '../../assets/film-1_bold.svg'
import textPrompt from '../../assets/note_bold.svg';
import edit from '../../assets/edit-1_bold-white.svg';
import qrCode from '../../assets/qr_bold.svg';
import API from "../../API";

function Topics() {

    const [topics, setTopics] = useState([] as Topic[]);
    const navigate = useNavigate();
    const qrUrlBase = "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=https%3A%2F%2Fwww.smalltownbig.org%2Fstories-redirect-";

    useEffect(() => {
        if (!sessionStorage.getItem("session_key")) {
            navigate("/");
            return;
        }
        API.getTopicsWithPrompts().then(topics => setTopics(topics));
    }, [navigate]);


    const displayPrompts = (prompts: Prompt[]) => {
        return prompts.slice(0, 8).map((prompt: Prompt) => {
                return (
                    <div className="side-by-side-fields align" key={prompt.id}>
                        <ul className="w-list-unstyled">
                            <li><p className="prompt-text">
                                {prompt.text}
                            </p></li>
                        </ul>
                        {prompt.media_type === 'text' &&
                        <>
                            <img src={textPrompt} loading="lazy" className="image-4" alt="text"/>
                        </>
                        }
                        {prompt.media_type === 'audio' &&
                        <>
                            <img src={audioPrompt} loading="lazy" className="image-4" alt="audio"/>
                        </>
                        }
                        {prompt.media_type === 'video' &&
                        <>
                            <img src={videoPrompt} loading="lazy" className="image-4" alt="video"/>
                        </>
                        }
                        {prompt.media_type === 'photo' &&
                        <>
                            <img src={photoPrompt} loading="lazy" className="image-4" alt=""/>
                        </>
                        }
                    </div>
                )
            }
        )
    }

    const displayTopics = () => {
        return topics.map((topic: Topic) => {
                return (
                    <div id="w-node-_67aecbe3-bc9b-406d-0bf3-c087de23eed8-9b2110a7" className="topic-block" key={topic.id}>
                        <h3>{topic.name}</h3>

                        <h4 className="heading-10">Prompts ({topic.prompts && topic.prompts.length})</h4>
                        {topic.prompts && displayPrompts(topic.prompts)}
                        <div>
                            <Link className="link" to={"/stories?topic=" + topic.name}>
                                View Stories for {topic.name}
                            </Link>
                        </div>

                        <div className="flex">
                            <Link to={`${topic.id}`} className="red-button w-button">
                                Edit Topic <img src={edit} alt="" className='button-image'/>
                            </Link>
                            <a href={`${qrUrlBase}${topic.name}.html`} target={"_blank"} rel={"noreferrer"}
                               className="bluebutton outline right-submit w-button">
                                View QR Code
                                <img src={qrCode} className='button-image' alt=""/>
                            </a>
                        </div>
                    </div>
                )
            }
        )
    }

    return (
        <div className='topics'>
            <div className="column-content w-col w-col-10">
                <div className="div-block-21">
                    <h2 className="sectionheader">Manage Topics</h2>
                </div>

                <div className="div-block-19">
                    {topics && displayTopics()}
                </div>
            </div>
        </div>
    );
}

export default Topics;